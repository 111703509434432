import { createTheme } from "@mui/material/styles";
import BabylonicaRegular from "./assets/fonts/babylonica-v1-latin-ext_latin-regular.woff";
import BabylonicaRegular2 from "./assets/fonts/babylonica-v1-latin-ext_latin-regular.woff2";

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Babylonica';
        font-style: normal;
        font-weight: 400;
        src: local(''),
             url('${BabylonicaRegular2}') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
             url('${BabylonicaRegular}') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      }
      `,
    },
  },
});

export default lightTheme;
