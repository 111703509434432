import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";
import ShareIcon from "@mui/icons-material/Share";
import { Typography } from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { useRef } from "react";

const actions = [
  { icon: <ShareIcon />, name: "Kontakt", i: 3 },
  { icon: <PrintIcon />, name: "Ceny", i: 2 },
  { icon: <SaveIcon />, name: "O mně", i: 1 },
  { icon: <FileCopyIcon />, name: "Úvod", i: 0 },
];

const cssCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const App = () => {
  const parallaxRef = useRef();

  return (
    <>
      <Parallax pages={4} ref={parallaxRef}>
        <ParallaxLayer
          offset={0}
          style={{ ...cssCenter, backgroundColor: "grey" }}
          speed={0.5}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontFamily: "Babylonica",
            }}
          >
            Mgr. Jana Nenutilová
          </Typography>
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={1.5} style={cssCenter}>
          <p>{actions[2].name}</p>
        </ParallaxLayer>
        <ParallaxLayer
          offset={2}
          speed={2}
          style={{ ...cssCenter, backgroundColor: "grey" }}
        >
          <p>{actions[1].name}</p>
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={3} style={cssCenter}>
          <p>{actions[0].name}</p>
        </ParallaxLayer>
      </Parallax>
      <SpeedDial
        ariaLabel="Menu"
        sx={{ position: "absolute", right: 32, bottom: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => parallaxRef.current.scrollTo(action.i)}
          />
        ))}
      </SpeedDial>
    </>
  );
};

export default App;
